import React from "react"
import Layout from "../components/Layout"

export default ({ location }) => (
  <Layout location={location}>
    <div className="section-inner">
      <h1>Oops, that's a 404: couldn't find what you're looking for...</h1>
    </div>
  </Layout>
)
